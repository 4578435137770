@media only screen and (min-width: 501px) {
  .About {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pageTitle {
    font-family: "Barlow", sans-serif;
    font-size: x-large;
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-bottom: 15vh;
    width: 70%;
  }

  .contentText {
    margin-right: 4vw;
    font-size: large;
    font-weight: 600;
  }

  p {
    text-align: justify;
    text-justify: inter-word;
  }
}

@media only screen and (max-width: 500px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    height: 40vh;
    width: 40vh;
  }

  .contentText {
    width: 80vw;
    margin-right: 4vw;
    font-size: medium;
    font-weight: 600;
  }

  p {
    text-align: center;
    text-justify: inter-word;
  }
}
