a {
  text-decoration: none;
  color: black;
  margin-left: 5vh;
  font-size: 3.5vh;
  font-weight: bold;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Barlow", sans-serif;
  border-collapse: collapse;
  height: 18vh;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  margin-bottom: 20px;
  text-align: center;
}

.headerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  margin-top: 1vw;
  margin-bottom: 1vh;
  margin-right: 2vh;
}

.logo {
  margin-top: 3vh;
  margin-left: 2.5vw;
  height: 12vh;
  width: 12vw;
}

.current {
  border: solid black 2px;
}

@media only screen and (max-width: 500px) {
  header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Barlow", sans-serif;
    border-collapse: collapse;
    height: 12vh;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
    text-align: center;
  }

  .logo {
    height: 8vh;
    width: 15vh;
    margin-bottom: 1.5vh;
  }
}
