select {
  background: none;
  outline: none;
  appearance: none;
  border: none;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  text-align-last: center;
  font-size: large;
  color: black;
  margin-bottom: 1vh;
}

.select-selected {
  background-color: DodgerBlue;
}

.menu {
  border: none;
}

.custom-select {
  color: black;
  width: 25vw;
}
