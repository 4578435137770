@media only screen and (min-width: 501px) {
  body {
    font-family: "Barlow", sans-serif;
  }

  .service-image {
    height: 50vh;
    width: 50vh;
  }

  .service-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-right: 18vh;
    margin-left: 25vh;
  }

  .service-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: large;
    font-weight: bolder;
    text-align: justify;
    margin-right: 8%;
  }
}

@media only screen and (max-width: 500px) {
  body {
    font-family: "Barlow", sans-serif;
  }

  .Service {
    width: 100%;
  }

  .service-image {
    height: 25vh;
    width: 25vh;
  }

  .service-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-right: 1vh;
    margin-left: 2vh;
  }

  .service-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: small;
    font-weight: bolder;
    text-align: justify;
    margin-right: 8%;
  }

  .service-title {
    font-size: medium;
  }
}
