@media only screen and (min-width: 501px) {
  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    font-family: "Barlow", sans-serif;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10vh;
  }

  .footerLogo {
    height: 6vh;
    width: 8vw;
  }

  .nameInfo {
    height: 10vh;
    display: contents;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footerlinkedin {
    height: 5vh;
    width: 5vh;
  }

  .pageLinks {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .Footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-family: "Barlow", sans-serif;
    font-size: 1.5vw;
  }

  .pageLinks {
    display: flex;
    flex-direction: column;
    font-size: 3vw;
  }

  .footerLogo {
    height: 4vh;
    width: 8vh;
  }
  .socialmedia {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  a {
    font-size: 2vh;
  }

  .footerlinkedin {
    height: 1.5vh;
    width: 1.5vh;
    margin-right: 10vw;
  }
}
