@media only screen and (min-width: 501px) {
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .workHeading {
    font-family: "Barlow", sans-serif;
    font-size: xx-large;
  }

  img {
    height: 40vh;
    width: 40vh;
    margin-right: 10%;
  }

  .servicesHome {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .service {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .serviceName {
    height: 10vh;
  }
}

@media only screen and (max-width: 500px) {
  .workHeading {
    font-family: "Barlow", sans-serif;
    font-size: x-large;
  }

  .serviceImg {
    height: 40vh;
    width: 40vh;
    margin-right: 10%;
  }

  .servicesHome {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .service {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }

  .serviceName {
    height: 5.5vh;
    font-size: small;
    margin-left: 8vw;
  }
}
