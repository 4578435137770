.Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contacttext {
  margin: 2vw;
  text-align: center;
  font-weight: 600;
}

.pageTitle {
  font-family: "Barlow", sans-serif;
  font-size: x-large;
}

.contactDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactIcon {
  height: 8vh;
  width: 8vh;
  border: none;
  background: none;
}

.contactRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 50vw;
  margin-bottom: 2vh;
}

.value {
  width: 12vw;
  font-weight: 600;
}

.logoLinks {
  width: 12vw;
  margin-left: 0.5vw;
}

.linkedinButton {
  margin-top: 2vh;
  width: 12vw;
  background-color: #0e76a8;
  border-radius: 2vw;
  font-family: "Barlow", sans-serif;
  color: white;
  font-size: medium;
  border: none;
  height: 5vh;
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .contactIcon {
    height: 6vh;
    width: 6vh;
    margin-top: 0.8vh;
    background: none;
  }

  .contactRow {
    display: flex;
    flex-direction: row;

    width: 80vw;
    margin-bottom: 2vh;
  }

  .value {
    width: 45vw;
    margin-left: 12 vw;
  }

  .logoLinks {
    width: 45vw;
    margin-left: 8 vw;
  }

  .linkedinButton {
    margin-top: 0.5vh;
    width: 30vw;
    background-color: #0e76a8;
    border-radius: 2vw;
    font-family: "Barlow", sans-serif;
    color: white;
    font-size: medium;
    border: none;
  }

  .contacttext {
    margin: 2vw;
    text-align: center;
    font-weight: 600;
    margin-bottom: 8vh;
  }

  .contactDiv {
    margin-right: 2vh;
  }
}